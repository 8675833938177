<template src="./invoiceDuplicate.html"></template>

<script>
import mixins from "../../../../mixins/mixins.js";

export default {
  mixins: [mixins],
  components: {
  },
  data() {
    return {
      invoices: [],
      invoicesPaginated: [],
      showWebTable: false,
      temp: "https://www.uol.com.br",
      windowWidth: 0,
      currentx: 1,
      numPages: 0,
      pagination: false,
    };
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  mounted: function () {
    var vm = this;
    vm.init();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  watch: {
    currentx: function () {
      const vm = this;
      vm.changePagination();
    },
  },
  methods: {
    init() {
      var vm = this;
      vm.getInvoices();
    },
    getInvoices() {
      var vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "payment/get-customer-signature-invoices/" +
            vm.$session.get("id"),
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          vm.invoices = response.data;
          vm.numPages = Math.ceil(vm.invoices / 10);
          vm.pagination = vm.numPages > 1;
          for (const inv in vm.invoices) {
            const datePaid = new Date(vm.invoices[inv].paid_at);
            vm.invoices[inv].paid_at = datePaid.toLocaleString("en-GB");
          }
          vm.changePagination();
          vm.showWebTable = true;
        });
    },
    generateSecond(id) {
      var vm = this;
      vm.axios
        .post(
          vm.$store.state.filooServer +
            "payment/duplicate-invoice/" + id, {},
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          console.log("Response: ");
          console.log(response);
          vm.$swal("Seu boleto foi gerado!", "Você será redirecionado para ele.", "success").then(function(){window.open(response.data.secure_url)});
        })
        .catch(function(){
          vm.$swal("Só é possivel gerar segunda via de boletos pendentes.", "", "warning");
        });
    },
    openNewWindow(url){
      window.open(url);
    },
    invoiceStatus(status) {
      if(status == "pending") {
        return "Pendente";
      }
      if(status == "canceled") {
        return "Cancelado";
      }
      if(status == "paid") {
        return "Pago";
      }
      return status;
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = window.innerWidth;
    },
    changePagination() {
      const vm = this;
      const invoicesToFilter = vm.invoices;
      if (vm.currentx === 1) {
        vm.invoicesPaginated = invoicesToFilter.slice(0, 10);
      } else {
        vm.invoicesPaginated = invoicesToFilter.slice(
          (vm.currentx - 1) * 10,
          vm.currentx * 10
        );
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" src="./invoiceDuplicate.scss">
</style>
